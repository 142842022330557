<template>
  <div class="sale-invoices-list fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="saleInvoicesList"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :zero-top-radius="true"
                             @filter:remove="setFilter($event, true)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @row:clicked="handleRowClicked"
                             @load:more="getInvoices"/>
  </div>
</template>

<script>
  import moment from 'moment-jalaali'
  import axios from 'axios'
  import {getSaleInvoices} from '@/http/requests/booking/sales'
  import {getTimes} from "../../../../../http/requests/club/times";
  import {getCourts} from "../../../../../http/requests/club/courts";
  import {addComma, getTimeFromServer} from "../../../../../assets/js/functions";
  import historyStates from "@/mixins/historyStates";

  export default {
    name: 'saleInvoicesList',
    metaInfo() {
      return {
        title: this.$t('sales.invoices.list.title')
      }
    },
    mixins: [historyStates],
    data() {
      return {
        options: {
          id: 'saleInvoicesListTable',
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'coach',
            i18n: 'sales.invoices.list.table.header.coach',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 150,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ]
          },
          {
            field: 'status',
            i18n: 'sales.invoices.list.table.header.status',
            align: 'center',
            width: 'calc(100% / 10)',
            minWidth: 150,
            sortable: true,
            filter: true,
            filterType: 'select',
            filterTypes: [
              {
                label: this.$t('sales.invoices.statusTypes.all'),
                value: 0
              },
              {
                label: this.$t('sales.invoices.statusTypes.active'),
                value: 1
              },
              {
                label: this.$t('sales.invoices.statusTypes.activePassed'),
                value: 3
              },
              {
                label: this.$t('sales.invoices.statusTypes.canceled'),
                value: 2
              },
              {
                label: this.$t('sales.invoices.statusTypes.canceledBySystem'),
                value: 4
              },
            ]
          },
          {
            field: 'finalPrice',
            i18n: 'sales.invoices.list.table.header.finalPrice',
            width: 'calc(100% / 10)',
            minWidth: 150,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'equals',
                name: 'برابر باشد',
                i18n: 'draggableTable.filter.types.equals',
                id: 1
              },
              {
                icon: 'not-equal',
                name: 'برابر نباشد',
                i18n: 'draggableTable.filter.types.notEqual',
                id: 2
              },
              {
                icon: 'less-than-equal',
                name: 'کوچکتر مساوی',
                i18n: 'draggableTable.filter.types.lessThanEquals',
                id: 4
              },
              {
                icon: 'greater-than-equal',
                name: 'بزرگتر مساوی',
                i18n:
                  'draggableTable.filter.types.greaterThanEquals',
                id: 3
              }
            ],
            footer: {}
          },
          {
            field: 'courtName',
            i18n: 'sales.invoices.list.table.header.courtName',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 100,
            sortable: true,
            filter: true,
            filterType: 'select',
            filterTypes: [
              {
                label: this.$t('sales.invoices.labels.all'),
                value: 0
              },
            ]
          },
          {
            field: 'time',
            i18n: 'sales.invoices.list.table.header.time',
            align: 'center',
            width: 'calc(100% / 10)',
            minWidth: 130,
            sortable: true,
            filter: true,
            filterType: 'select',
            filterTypes: [
              {
                label: this.$t('sales.invoices.labels.all'),
                value: 0
              },
            ]
          },
          {
            field: 'reserveDate',
            i18n: 'sales.invoices.list.table.header.reserveDate',
            align: 'center',
            width: 'calc(100% / 10)',
            minWidth: 100,
            sortable: true,
            filter: true,
            filterType: 'date',
            filterRange: true,
            // filterDefault: this.$t('sales.invoices.labels.fromNow')
          },
          {
            field: 'user',
            i18n: 'sales.invoices.list.table.header.user',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 150,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ]
          },
          {
            field: 'registryDate',
            i18n: 'sales.invoices.list.table.header.registryDate',
            align: 'center',
            width: 'calc(100% / 10)',
            minWidth: 120,
            sortable: true,
            filter: true,
            filterType: 'date',
            filterRange: true
          },
          {
            field: 'creator',
            i18n: 'sales.invoices.list.table.header.creator',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 130,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ]
          },
          {
            field: 'id',
            i18n: 'sales.invoices.list.table.header.docNumber',
            align: 'center',
            width: 'calc(100% / 10)',
            minWidth: 80,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ],
            footer: {}
          }
        ],
        invoiceProcesses: [
          {
            label: this.$t('sales.invoices.processTypes.preInvoice'),
            value: 1
          },
          {
            label: this.$t('sales.invoices.processTypes.invoice'),
            value: 2
          },
        ],
        invoiceStatus: {
          1: {
            value: this.$t('sales.invoices.statusTypes.active'),
            // styles: 'color: rgb(119, 119, 119);'
          },
          2: {
            value: this.$t('sales.invoices.statusTypes.canceled'),
            // styles: 'color: rgb(108, 135, 41);background: rgba(108, 135, 41, .1);'
          },
          3: {
            value: this.$t('sales.invoices.statusTypes.activePassed'),
            // styles: 'color: #bb0900;background: #f8e0e0;'
          },
          4: {
            value: this.$t('sales.invoices.statusTypes.canceledBySystem'),
            // styles: 'color: #bb0900;background: #f8e0e0;'
          }
        },
        financialStatus: {
          1: {
            value: this.$t('sales.invoices.financialStatusTypes.debtor'),
            // styles: 'color: rgb(108, 135, 41);background: rgba(108, 135, 41, .1);'
          },
          2: {
            value: this.$t('sales.invoices.financialStatusTypes.checkout'),
            // styles: 'color: rgb(119, 119, 119);'
          },
        },
        invoiceActionTypes: {
          1: this.$t('sales.invoices.actionTypes.present'),
          2: this.$t('sales.invoices.actionTypes.tel'),
          3: this.$t('sales.invoices.actionTypes.internet')
        },
        selectedFilters: [],
        filters: {timeDate: `${moment(getTimeFromServer()).format(this.$localeValidator('moment.date'))},3`},
        sorts: ['order[0]=created_at,desc'],
        data: [],
        page: 1,
        total_count: null,
        loadingTimer: 0,
        actions: [
          {
            toolbar: [
              {
                id: {name: 'insertSaleInvoice'},
                type: 'link',
                i18n: '',
                icon: 'PLUS',
                iconPack: 'useral',
                color: 'success',
                permission: 'invoice.create'
              },
              {
                id: {name: 'insertPastSaleInvoice'},
                type: 'link',
                i18n: '',
                icon: 'INSERT_PAST',
                iconPack: 'useral',
                color: 'warning',
                permission: 'invoice.forget'
              }
            ],
            leftToolbar: [
              {
                id: 'printTable',
                // i18n: 'draggableDynamicTable.actions.print',
                icon: 'PRINT',
                iconPack: 'useral'
              },
              {
                id: {name: 'saleInvoicesTrash'},
                type: 'link',
                icon: 'TRASH',
                iconPack: 'useral',
                permission: 'invoice.delete'
              },
              {
                id: 'settingTable',
                icon: 'icon-settings',
                iconPack: 'feather'
              }
            ]
          }
        ]
      }
    },
    created() {
      this.$nextTick(() => {
        this.$store.dispatch('auth/setAccessToken')
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      })

      if (history.state.hasOwnProperty('data') && this.$store.state.helper.prevRoute.name !== null) {
        this.getTableInfoFromHistory()
      } else {
        this.getInvoices()
      }
      this.getTimes()
      this.getCourts()
    },
    methods: {
      getInvoices() {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (this.data.length < this.total_count || !this.total_count) {
            if (this.$refs.saleInvoicesList && this.data.length === 0) this.$refs.saleInvoicesList.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.saleInvoicesList && this.data.length > 0) this.$refs.saleInvoicesList.loadMoreStatus = 'Loading'

            getSaleInvoices(this.page, this.filters, this.sorts).then((response) => {
              const invoices = response.data

              invoices.data.forEach((invoice) => {
                let status = ''
                if (invoice.passed) {
                  if (invoice.status === 1) {
                    status = this.invoiceStatus[3]
                  } else if (invoice.status === 2) {
                    status = invoice.cancel_type === 1 ? this.invoiceStatus[2] : this.invoiceStatus[4]
                  }
                } else {
                  if (invoice.status === 1) {
                    status = this.invoiceStatus[invoice.status]
                  } else if (invoice.status === 2) {
                    status = this.invoiceStatus[2]
                    if (invoice.cancel_type === 2) {
                      status = this.invoiceStatus[4]
                    }
                  }
                }

                this.data.push({
                  route: {name: 'saleInvoice', params: {id: invoice.id}},
                  id: invoice.invoice_number || invoice.id,
                  registryDate: invoice.invoice_date,
                  reserveDate: invoice.reserved_time && invoice.reserved_time.date ? invoice.reserved_time.date : '',
                  time: {
                    value: invoice.reserved_time && invoice.reserved_time.time ? `${invoice.reserved_time.time.start_time.substr(0, 5)} ${this.$t('sales.invoices.labels.until')} ${invoice.reserved_time.time.end_time.substr(0, 5)} ` : '',
                    styles: `direction: ${this.$vs.rtl ? 'rtl' : 'ltr'};`
                  },
                  courtName: invoice.reserved_time.court.name || '',
                  finalPrice: {value: invoice.total_price || '0', type: 'price'},
                  user: `${invoice.user.name} ${invoice.user.family}`,
                  coach: invoice.coach ? `${invoice.coach.name} ${invoice.coach.family}` : '',
                  creator: invoice.creator.name,
                  receivedId: invoice.received_id || '-',
                  type: this.invoiceActionTypes[invoice.action_type],
                  status: status,
                  timeStatus: invoice.passed ? this.$t('sales.invoices.labels.passed') : this.$t('sales.invoices.labels.active'),
                  reserveStatus: invoice.status === 1 ? this.$t('sales.invoices.labels.reserved') : this.$t('sales.invoices.labels.canceled'),
                  styles: invoice.status === 2 ? 'background: #f8e0e0;' : invoice.passed ? 'background: #f0f0f0;' : ''
                })
              })

              this.total_count = invoices.pagination.total
              this.page = invoices.pagination.current_page + 1

              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('id')
                this.columnsLabel[row_index].footer.value = response.data.pagination.total
              }
              const price_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('finalPrice')
              if (price_index > -1) {
                this.columnsLabel[price_index].footer = {
                  value: addComma(response.data.extra.total_price)
                }
              }

              if (this.$refs.saleInvoicesList) this.$refs.saleInvoicesList.loadMoreStatus = ''

            }).catch((error) => {
              if (this.$refs.saleInvoicesList && !axios.isCancel(error)) this.$refs.saleInvoicesList.loadMoreStatus = 'Danger'

            })
          }
        }, 400)
      },
      getInvoiceStatus(id) {
        return this.invoiceProcesses[id - 1].label
      },

      getTimes() {
        getTimes().then(response => {
          const times = response.data.data
          const time_index = this.columnsLabel.map(e => e.field).indexOf('time')
          if (time_index > -1) {
            times.forEach((time) => {
              this.columnsLabel[time_index].filterTypes.push({
                value: time.id,
                label: `${time.start_time.substr(0, 5)} ${this.$t('sales.invoices.labels.until')} ${time.end_time.substr(0, 5)}`
              })
            })
          }
        })
      },
      getCourts() {
        getCourts(1).then((response) => {
          const courts = response.data.data
          const court_index = this.columnsLabel.map(e => e.field).indexOf('courtName')
          courts.forEach((court) => {
            this.columnsLabel[court_index].filterTypes.push({
              value: court.id,
              label: court.name
            })
          })
        })
      },
      handleRowClicked () {
        this.setTableInfoToHistory()
      },
      setFilter(filters) {
        this.selectedFilters = filters
        let filters_list = {}
        Object.keys(filters).forEach((key) => {
          switch (key) {

            case 'id':
              if (filters[key].search !== '') filters_list.invoiceNumber = filters[key].search
              break

            case 'receivedId':
              if (filters[key].search !== '') filters_list.receivedId = filters[key].search
              break

            case 'creator':
              let search = filters[key].search
              if (search === 'سیستم' || search === 'سیستمی') {
                search = 'system'
              }
              if (filters[key].search !== '') filters_list.creator = search
              break

            case 'user':
              if (filters[key].search !== '') filters_list.user = filters[key].search
              break

            case 'coach':
              if (filters[key].search !== '') filters_list.coach = filters[key].search
              break

            case 'registryDate':
              if (filters[key].search.length > 0) filters_list.date = filters[key].search.join('_')
              break

            case 'reserveDate':
              if (filters[key].search === this.$t('sales.invoices.labels.fromNow')) {
                filters_list.timeDate = `${moment(getTimeFromServer()).format(this.$localeValidator('moment.date'))},3`
              } else if (filters[key].search.length > 0) filters_list.timeDate = filters[key].search.join('_')
              break

            case 'discount':
              if (filters[key].search !== '') filters_list.discount = `${filters[key].search},${filters[key].type.id}`
              break

            case 'finalPrice':
              if (filters[key].search !== '') filters_list.total_price = `${filters[key].search},${filters[key].type.id}`
              break

            case 'courtName':
              if (filters[key].search.value > 0) filters_list.court = filters[key].search.value
              break

            case 'time':
              if (filters[key].search.value > 0) filters_list.time = filters[key].search.value
              break

            case 'type':
              if (filters[key].search.value > 0) filters_list.actionType = filters[key].search.value
              break

            case 'status':
              if (filters[key].search.value === 1) {
                filters_list.passed = `0`
                filters_list.status = `1`
              } else if (filters[key].search.value === 2) {
                filters_list.status = `2`
              } else if (filters[key].search.value === 3) {
                filters_list.passed = `1`
                filters_list.status = `1`
              } else if (filters[key].search.value === 4) {
                filters_list.cancelType = `2`
                filters_list.status = `2`
              } else if (filters[key].search.value > 0) filters_list.status = filters[key].search.value
              break

            case 'financialStatus':
              if (filters[key].search.value > 0) filters_list.financialStatus = filters[key].search.value
              break
          }
        })

        this.data = []
        this.page = 1
        this.total_count = 0
        this.filters = filters_list
        this.$nextTick(() => {
          this.getInvoices()
        })
      },
      setSort(sorts) {
        const sorts_list = []
        Object.keys(sorts).forEach((key) => {
          switch (key) {
            case 'id':
              sorts_list.push(`order[0]=id,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'receivedId':
              sorts_list.push(`order[0]=received_id,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'creator':
              sorts_list.push(`order[0]=creator,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'registryDate':
              sorts_list.push(`order[0]=created_at,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'user':
              sorts_list.push(`order[0]=user,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'coach':
              sorts_list.push(`order[0]=coach,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'type':
              sorts_list.push(`order[0]=type,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'status':
              sorts_list.push(`order[0]=status,${sorts[key] ? 'desc' : 'asc'},order[1]=time,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'date':
              sorts_list.push(`order[0]=invoice_date,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'courtName':
              sorts_list.push(`order[0]=court,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'reserveDate':
              sorts_list.push(`order[0]=reserved_date,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'time':
              sorts_list.push(`order[0]=time,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'discount':
              sorts_list.push(`order[0]=discount,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'finalPrice':
              sorts_list.push(`order[0]=total_price,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'financialStatus':
              sorts_list.push(`order[0]=financial_status,${sorts[key] ? 'desc' : 'asc'}`)
              break
          }
        })

        if (sorts_list.length === 0) {
          this.sorts.push('order[0]=updated_at,desc')
        }

        this.data = []
        this.page = 1
        this.total_count = 0
        this.sorts = sorts_list
        this.$nextTick(() => {
          this.getInvoices()
        })
      }
    }
  }
</script>

<style scoped>

</style>
